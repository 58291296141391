import { FuseNavigation } from '@fuse/types';
import { E_MenuId } from 'app/services/navigation.service';

export const navigation: FuseNavigation[] = [
  {
    id: 'applications',
    title: 'Menu',
    translate: 'NAV.APPLICATIONS',
    type: 'group',
    children: [
      // {
      //   id: E_MenuId.dashboard,
      //   title: 'Dashboard',
      //   translate: 'NAV.DASHBOARD',
      //   type: 'item',
      //   isCustomIcon: true,
      //   icon: 'dashboard_icon',
      //   url: '/app/dashboard'
      // },
      // {
      //   id: E_MenuId.stock,
      //   title: 'Stock',
      //   translate: 'NAV.STOCK',
      //   type: 'item',
      //   isCustomIcon: true,
      //   icon: 'stock_icon',
      //   url: '/app/stock'
      // },
      {
        id: E_MenuId.vm_status,
        title: 'Kiosk Status',
        translate: 'NAV.VM_STATUS',
        type: 'item',
        isCustomIcon: true,
        icon: 'vm_status_icon',
        url: '/app/vm-status',
        exactMatch: true,
        haveCreate: true,
        haveUpdate: true,
        haveExport: true,
      },
      {
        id: E_MenuId.vm_performance,
        title: 'Kiosk Performance',
        translate: 'NAV.LOGANDHISTORY.VM_PERFORMANCE',
        type: 'item',
        url: 'app/vm-operation/performance',
        exactMatch: true,
        haveExport: true,
      },
      // {
      //   id: 'logAndHistory',
      //   title: 'Log & History',
      //   translate: 'NAV.LOGANDHISTORY.TITLE',
      //   type: 'collapsable',
      //   icon: 'history',
      //   children: [
      //     {
      //       id: E_MenuId.machine_history,
      //       title: 'Machine History',
      //       translate: 'NAV.LOGANDHISTORY.MACHINE_HISTORY',
      //       type: 'item',
      //       url: 'app/history/machine-history',
      //       exactMatch: true,
      //       haveExport: true
      //     },
      //     {
      //       id: E_MenuId.vm_operation,
      //       title: 'VM Operation',
      //       translate: 'NAV.LOGANDHISTORY.VM_OPERATION',
      //       type: 'item',
      //       url: 'app/vm-operation',
      //       exactMatch: true,
      //       haveExport: true,
      //       haveCreate: true,
      //       haveUpdate: true
      //     },
      //   ]
      // },
      // {
      //   id: 'vending_cash',
      //   title: 'Vending Cash',
      //   translate: 'NAV.VENDING_CASH.TITLE',
      //   type: 'collapsable',
      //   isCustomIcon: true,
      //   icon: 'vm_cash_icon',
      //   children: [
      //     {
      //       id: E_MenuId.cash_inventory,
      //       title: 'Cash Inventory',
      //       translate: 'NAV.VENDING_CASH.CASH_INVEN',
      //       type: 'item',
      //       url: '/app/cash/inventory',
      //       exactMatch: true,
      //       haveExport: true
      //     },
      //     {
      //       id: E_MenuId.change_coin_alert,
      //       title: 'Change Coin Alert',
      //       translate: 'NAV.VENDING_CASH.CHANGE_COIN_ALERT',
      //       type: 'item',
      //       url: '/app/cash/coin-alert',
      //       exactMatch: true,
      //       haveExport: true
      //     }
      //   ]
      // },
      {
        id: 'report',
        title: 'Report',
        translate: 'NAV.REPORT.TITLE',
        type: 'collapsable',
        isCustomIcon: true,
        icon: 'report_icon',
        children: [
          {
            id: 'report_transaction',
            title: 'Transaction',
            translate: 'NAV.REPORT.REPORT_TRANSACTIOM',
            type: 'collapsable',
            children: [
              {
                id: E_MenuId.sales_tran_report,
                title: 'Successful Transaction Report',
                translate: 'NAV.REPORT.SALE_TRAN_REPORT',
                type: 'item',
                url: '/app/report/sales-tran',
                exactMatch: true,
                haveExport: true,
              },
              {
                id: E_MenuId.sale_fail_report,
                title: 'Failed Transaction Report',
                translate: 'NAV.REPORT.SALE_FAIL_REPORT',
                type: 'item',
                url: '/app/report/sale-fail',
                exactMatch: true,
                haveExport: true,
              },
              {
                id: E_MenuId.daily_shift_report,
                title: 'Sale Shift Report',
                translate: 'NAV.REPORT.DAILY_SHIFT_REPORT',
                type: 'item',
                url: '/app/report/daily-shift',
                exactMatch: true,
                haveExport: true,
              },
            ],
          },
          {
            id: 'bill_payment',
            title: 'Report',
            translate: 'NAV.REPORT.BILL_PAYMENT',
            type: 'collapsable',
            children: [
              {
                id: E_MenuId.detail_tran_report,
                title: 'Detail Transaction Report',
                translate: 'NAV.REPORT.DETAIL_TRAN_REPORT',
                type: 'item',
                url: '/app/report/detail-tran',
                exactMatch: true,
                haveExport: true,
              },
              {
                id: E_MenuId.card_tran_report,
                title: 'Credit Card Transaction Report',
                translate: 'NAV.REPORT.CARD_TRAN_REPORT',
                type: 'item',
                url: '/app/report/card-tran',
                exactMatch: true,
                haveExport: true,
              },
              {
                id: E_MenuId.settlement_report,
                title: 'Settlement Report',
                translate: 'NAV.REPORT.SETTLEMENT',
                type: 'item',
                url: '/app/report/settlement',
                exactMatch: true,
                haveExport: true,
              },
              {
                id: E_MenuId.settlement_card_report,
                title: 'Settlement Credit Report',
                translate: 'NAV.REPORT.SETTLEMENT_CARD',
                type: 'item',
                url: '/app/report/settlement-credit',
                exactMatch: true,
                haveExport: true,
              },
              {
                id: E_MenuId.void_tran_report,
                title: 'Cancel Transaction Report',
                translate: 'NAV.REPORT.CANCEL_TRAN_REPORT',
                type: 'item',
                url: '/app/report/void-tran',
                exactMatch: true,
                haveExport: true,
              },
            ],
          },
          {
            id: E_MenuId.hardware_error,
            title: 'Validate Hardware & Software Error',
            translate: 'NAV.REPORT.HARDWARE_ERROR',
            type: 'item',
            url: '/app/report/hw-error',
            exactMatch: true,
            haveExport: true,
          },
        ],
      },
      // {
      //   id: 'promotion_data',
      //   title: 'Promotion',
      //   translate: 'NAV.PROMOTION_DATA.TITLE',
      //   type: 'collapsable',
      //   isCustomIcon: true,
      //   icon: 'campaign_icon',
      //   children: [
      //     {
      //       id: E_MenuId.ms_promotions,
      //       title: 'Promotions',
      //       translate: 'NAV.PROMOTION_DATA.PROMOTIONS',
      //       type: 'item',
      //       url: 'app/promotions',
      //       exactMatch: true,
      //       haveCreate: true,
      //       haveUpdate: true
      //     },
      //     {
      //       id: E_MenuId.ms_promotion_plans,
      //       title: 'Promotion Plans',
      //       translate: 'NAV.PROMOTION_DATA.PROMOTION_PLANS',
      //       type: 'item',
      //       url: 'app/promotions/plan',
      //       exactMatch: true,
      //       haveCreate: true,
      //       haveUpdate: true
      //     }
      //   ]
      // },
      {
        id: 'ads_campaign',
        title: 'Ads',
        translate: 'NAV.ADS_CAMPAIGN.TITLE',
        type: 'collapsable',
        icon: 'music_video',
        children: [
          {
            id: E_MenuId.ms_ads_display_group,
            title: 'Ads Group',
            translate: 'NAV.ADS_CAMPAIGN.ADS_DISPLAY_GROUP',
            type: 'item',
            url: 'app/campaign/ads-display-group',
            exactMatch: true,
            haveCreate: true,
            haveUpdate: true,
          },
          {
            id: E_MenuId.ms_ads_campaign,
            title: 'Ads Campaign',
            translate: 'NAV.ADS_CAMPAIGN.CAMPAIGN',
            type: 'item',
            url: 'app/campaign/ads-campaign',
            exactMatch: true,
            haveCreate: true,
            haveUpdate: true,
          },
          {
            id: E_MenuId.ms_ads_plan,
            title: 'Ads Campaign Plan',
            translate: 'NAV.ADS_CAMPAIGN.ADS_PLAN',
            type: 'item',
            url: 'app/campaign/ads-plan',
            exactMatch: true,
            haveCreate: true,
            haveUpdate: true,
          },
        ],
      },
      // {
      //   id: 'ads_campaign_middle',
      //   title: 'Banner 1',
      //   translate: 'NAV.ADS_CAMPAIGN.TITLE_MIDDLE',
      //   type: 'collapsable',
      //   icon: 'music_video',
      //   children: [
      //     {
      //       id: E_MenuId.ms_ads_display_group_middle,
      //       title: '[ MIDDLE ] Ads Group',
      //       translate: 'NAV.ADS_CAMPAIGN.ADS_DISPLAY_GROUP_MIDDLE',
      //       type: 'item',
      //       url: 'app/campaign/ads-display-group-middle',
      //       exactMatch: true,
      //       haveCreate: true,
      //       haveUpdate: true
      //     },
      //     {
      //       id: E_MenuId.ms_ads_campaign_middle,
      //       title: 'Banner 1 Campaign',
      //       translate: 'NAV.ADS_CAMPAIGN.CAMPAIGN_MIDDLE',
      //       type: 'item',
      //       url: 'app/campaign/ads-campaign-middle',
      //       exactMatch: true,
      //       haveCreate: true,
      //       haveUpdate: true
      //     },
      //     {
      //       id: E_MenuId.ms_ads_plan_middle,
      //       title: 'Banner 1 Campaign Plan',
      //       translate: 'NAV.ADS_CAMPAIGN.ADS_PLAN_MIDDLE',
      //       type: 'item',
      //       url: 'app/campaign/ads-plan-middle',
      //       exactMatch: true,
      //       haveCreate: true,
      //       haveUpdate: true
      //     }
      //   ]
      // },
      // {
      //   id: 'ads_campaign_bottom',
      //   title: 'Banner 2',
      //   translate: 'NAV.ADS_CAMPAIGN.TITLE_BOTTOM',
      //   type: 'collapsable',
      //   icon: 'music_video',
      //   children: [
      //     {
      //       id: E_MenuId.ms_ads_display_group_bottom,
      //       title: '[ BOTTOM ] Ads Group',
      //       translate: 'NAV.ADS_CAMPAIGN.ADS_DISPLAY_GROUP_BOTTOM',
      //       type: 'item',
      //       url: 'app/campaign/ads-display-group-bottom',
      //       exactMatch: true,
      //       haveCreate: true,
      //       haveUpdate: true
      //     },
      //     {
      //       id: E_MenuId.ms_ads_campaign_bottom,
      //       title: 'Banner 2 Campaign',
      //       translate: 'NAV.ADS_CAMPAIGN.CAMPAIGN_BOTTOM',
      //       type: 'item',
      //       url: 'app/campaign/ads-campaign-bottom',
      //       exactMatch: true,
      //       haveCreate: true,
      //       haveUpdate: true
      //     },
      //     {
      //       id: E_MenuId.ms_ads_plan_bottom,
      //       title: 'Banner 2 Campaign Plan',
      //       translate: 'NAV.ADS_CAMPAIGN.ADS_PLAN_BOTTOM',
      //       type: 'item',
      //       url: 'app/campaign/ads-plan-bottom',
      //       exactMatch: true,
      //       haveCreate: true,
      //       haveUpdate: true
      //     }
      //   ]
      // },
      // {
      //   id: 'vending_master_data',
      //   title: 'Vending Master',
      //   translate: 'NAV.VENDING_MASTER.TITLE',
      //   type: 'collapsable',
      //   icon: 'domain',
      //   children: [
      //     {
      //       id: E_MenuId.ms_vending_config,
      //       title: 'Vending Config',
      //       translate: 'NAV.VENDING_MASTER.VENDING_CONFIG',
      //       type: 'item',
      //       url: 'app/master-data/vending-config',
      //       exactMatch: true,
      //       haveCreate: true,
      //       haveUpdate: true
      //     },
      // {
      //   id: E_MenuId.ms_vending_profile,
      //   title: 'Vending Profile',
      //   translate: 'NAV.VENDING_MASTER.VENDING_PROFILE',
      //   type: 'item',
      //   url: 'app/master-data/vending-profile',
      //   exactMatch: true
      // },
      //     {
      //       id: E_MenuId.ms_vending_cat,
      //       title: 'Vending Segment',
      //       translate: 'NAV.VENDING_MASTER.VENDING_CAT',
      //       type: 'item',
      //       url: 'app/master-data/vending-category',
      //       exactMatch: true,
      //       haveCreate: true,
      //       haveUpdate: true,
      //       haveDelete: true
      //     },
      // {
      //   id: E_MenuId.ms_vending_group,
      //   title: 'Vending Group',
      //   translate: 'NAV.VENDING_MASTER.VENDING_GROUP',
      //   type: 'item',
      //   url: 'app/master-data/vending-group',
      //   exactMatch: true
      // },
      //     {
      //       id: E_MenuId.ms_fullfillment,
      //       title: 'Fulfillment',
      //       translate: 'NAV.VENDING_MASTER.FULLFILLMENT',
      //       type: 'item',
      //       url: 'app/vm-status/full-fill-ment',
      //       exactMatch: true,
      //       haveCreate: true,
      //       haveUpdate: true,
      //       haveDelete: true
      //     },
      //     {
      //       id: E_MenuId.ms_siteowner,
      //       title: 'Site Owner',
      //       translate: 'NAV.VENDING_MASTER.SITEOWNER',
      //       type: 'item',
      //       url: 'app/vm-status/site-owner',
      //       exactMatch: true,
      //       haveCreate: true,
      //       haveUpdate: true,
      //       haveDelete: true
      //     },
      //     {
      //       id: E_MenuId.ms_tdsrgm,
      //       title: 'TDS RGM',
      //       translate: 'NAV.VENDING_MASTER.TDS_RGM',
      //       type: 'item',
      //       url: 'app/vm-status/tds-rgm',
      //       exactMatch: true,
      //       haveCreate: true,
      //       haveUpdate: true,
      //       haveDelete: true
      //     },
      //     {
      //       id: E_MenuId.ms_cbd,
      //       title: 'CBD',
      //       translate: 'NAV.VENDING_MASTER.CBD',
      //       type: 'item',
      //       url: 'app/vm-status/cbd',
      //       exactMatch: true,
      //       haveCreate: true,
      //       haveUpdate: true,
      //       haveDelete: true
      //     }
      //   ]
      // },
      {
        id: 'master_data',
        title: 'Master Data',
        translate: 'NAV.MASTER_DATA.TITLE',
        type: 'collapsable',
        isCustomIcon: true,
        icon: 'master_data_icon',
        children: [
          // {
          //   id: E_MenuId.ms_company,
          //   title: 'Company',
          //   translate: 'NAV.MASTER_DATA.COMPANY',
          //   type: 'item',
          //   url: 'app/master-data/company',
          //   exactMatch: true,
          //   haveCreate: true,
          //   haveUpdate: true
          // },
          // {
          //   id: E_MenuId.ms_brand,
          //   title: 'Brand',
          //   translate: 'NAV.MASTER_DATA.BRAND',
          //   type: 'item',
          //   url: 'app/master-data/brand',
          //   exactMatch: true,
          //   haveCreate: true,
          //   haveUpdate: true
          // },
          // {
          //   id: E_MenuId.ms_branch,
          //   title: 'Branch',
          //   translate: 'NAV.MASTER_DATA.BRANCH',
          //   type: 'item',
          //   url: 'app/master-data/branch',
          //   exactMatch: true,
          //   haveCreate: true,
          //   haveUpdate: true
          // },
          // {
          //   id: E_MenuId.ms_product_category,
          //   title: 'Product Category',
          //   translate: 'NAV.MASTER_DATA.PRODUCT_CAT',
          //   type: 'item',
          //   url: 'app/master-data/product-category',
          //   exactMatch: true,
          //   haveCreate: true,
          //   haveUpdate: true
          // },
          // {
          //   id: E_MenuId.ms_product,
          //   title: 'Product',
          //   translate: 'NAV.MASTER_DATA.PRODUCT',
          //   type: 'item',
          //   url: 'app/master-data/product',
          //   exactMatch: true,
          //   haveCreate: true,
          //   haveUpdate: true
          // },
          // {
          //   id: E_MenuId.ms_tag,
          //   title: 'Tag',
          //   translate: 'NAV.MASTER_DATA.TAG',
          //   type: 'item',
          //   url: 'app/master-data/tag',
          //   exactMatch: true,
          //   haveCreate: true,
          //   haveUpdate: true,
          //   haveDelete: true
          // },
          // {
          //   id: E_MenuId.ms_basket,
          //   title: 'Product Basket',
          //   translate: 'NAV.MASTER_DATA.PRODUCT_BASKET',
          //   type: 'item',
          //   url: 'app/master-data/basket',
          //   exactMatch: true,
          //   haveCreate: true,
          //   haveUpdate: true,
          //   haveDelete: true
          // },
          // {
          //   id: E_MenuId.ms_menu,
          //   title: 'Browsing Menu',
          //   translate: 'NAV.MASTER_DATA.BROWSING_MENU',
          //   type: 'item',
          //   url: 'app/master-data/menu',
          //   exactMatch: true,
          //   haveCreate: true,
          //   haveUpdate: true,
          //   haveDelete: true
          // },
          // {
          //   id: E_MenuId.slot_template,
          //   title: 'Planogram Template',
          //   translate: 'NAV.MASTER_DATA.SLOT_TEMPLATE',
          //   type: 'item',
          //   url: 'app/master-data/slot-template',
          //   exactMatch: true,
          //   haveCreate: true,
          //   haveUpdate: true
          // }
        ],
      },
      // {
      //   id: 'payment_config',
      //   title: 'Payment Config',
      //   translate: 'NAV.PAYMENT_CONFIG.TITLE',
      //   type: 'collapsable',
      //   icon: 'payment',
      //   children: [
      //     {
      //       id: E_MenuId.ms_payment_tmn_payment_config,
      //       title: 'TMN Payment Config',
      //       translate: 'NAV.PAYMENT_CONFIG.TMN_PAYMENT',
      //       type: 'item',
      //       url: 'app/payment-config/tmn-payment',
      //       exactMatch: true,
      //       haveCreate: true,
      //       haveUpdate: true,
      //       haveDelete: true
      //     },
      //     {
      //       id: E_MenuId.ms_payment_tmn_bill_config,
      //       title: 'TMN Bill Config',
      //       translate: 'NAV.PAYMENT_CONFIG.TMN_BILL',
      //       type: 'item',
      //       url: 'app/payment-config/tmn-bill',
      //       exactMatch: true,
      //       haveCreate: true,
      //       haveUpdate: true,
      //       haveDelete: true
      //     },
      //     {
      //       id: E_MenuId.ms_payment_tmn_topup_config,
      //       title: 'TMN Topup Config',
      //       translate: 'NAV.PAYMENT_CONFIG.TMN_TOPUP',
      //       type: 'item',
      //       url: 'app/payment-config/tmn-topup',
      //       exactMatch: true,
      //       haveCreate: true,
      //       haveUpdate: true,
      //       haveDelete: true
      //     },
      //     {
      //       id: E_MenuId.ms_payment_weomni_config,
      //       title: 'Weomni Config',
      //       translate: 'NAV.PAYMENT_CONFIG.WEOMNI_CONFIG',
      //       type: 'item',
      //       url: 'app/payment-config/weomni-config',
      //       exactMatch: true,
      //       haveCreate: true,
      //       haveUpdate: true,
      //       haveDelete: true
      //     }
      //   ]
      // },
      {
        id: E_MenuId.staff,
        title: 'Staff',
        translate: 'NAV.STAFF',
        type: 'item',
        isCustomIcon: true,
        icon: 'auth_icon',
        url: '/app/staff',
        haveCreate: true,
        haveUpdate: true,
        haveDelete: true,
      },
      {
        id: E_MenuId.role_management,
        title: 'Role Management',
        translate: 'NAV.ROLE_MANAGEMENT',
        type: 'item',
        icon: 'perm_data_setting',
        url: '/app/role',
        haveCreate: true,
        haveUpdate: true,
      },
    ],
  },
];
