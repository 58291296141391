<mat-toolbar class="p-0 mat-elevation-z1">

  <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">

    <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">

      <button mat-icon-button class="navbar-toggle-button" aria-label="menu" *ngIf="!hiddenNavbar && !rightNavbar"
        (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
        <mat-icon class="secondary-text">menu</mat-icon>
      </button>

      <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>

      <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
        <div class="logo ml-16">
          <img class="logo-icon" src="assets/images/logos/img_logo.svg" alt="Logo">
          <span *ngIf="envName !== 'production'" class="logo-text primary-500-fg">
            [{{ envName | titlecase }}]
          </span>
        </div>
      </div>

      <!-- <div class="px-8 px-md-16">
        <fuse-shortcuts [navigation]="navigation"></fuse-shortcuts>
      </div> -->

    </div>

    <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center" *ngIf="staff$ | async as staff">

      <ng-container *ngFor="let item of menuTools">
        <ng-container *ngIf="item.type === 'item'; else itemContent">
          <a mat-button [routerLink]="item.url" [routerLinkActive]="['active', 'active-link']"
            [routerLinkActiveOptions]="{exact: item.exactMatch || false}" class="user-button normal-button" fxHide
            fxShow.gt-md *ngIf="!item.hidden">
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
              <mat-icon *ngIf="item.icon && !item.isCustomIcon">{{item.icon}}</mat-icon>
              <mat-icon *ngIf="item.icon && item.isCustomIcon" [svgIcon]="item.icon"></mat-icon>
              <span>{{(item.translate | translate) || item.title}}</span>
            </div>
          </a>
        </ng-container>
        <ng-template #itemContent>
          <button mat-button [matMenuTriggerFor]="menu.childMenu" class="user-button" fxHide fxShow.gt-md
            *ngIf="!item.hidden">
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
              <mat-icon *ngIf="item.icon && !item.isCustomIcon">{{item.icon}}</mat-icon>
              <mat-icon *ngIf="item.icon && item.isCustomIcon" [svgIcon]="item.icon"></mat-icon>
              <span class="username mr-12" fxHide fxShow.gt-sm>{{(item.translate | translate) || item.title}}</span>
              <mat-icon class="s-16" fxHide.xs>expand_more</mat-icon>
            </div>
          </button>
          <app-child-menu-item #menu [items]="item.children"></app-child-menu-item>
        </ng-template>
      </ng-container>

      <!-- User -->
      <button mat-button [matMenuTriggerFor]="userMenu" class="user-button">
        <div fxLayout="row" fxLayoutAlign="center center">
          <img class="avatar mr-0 mr-sm-16" src="assets/images/avatars/img_profile.svg" alt="Profile Image">
          <!-- <span class="username mr-12" fxHide fxShow.gt-sm>{{ staff?.first_name }} {{ staff?.last_name }}</span>
          <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon> -->
        </div>
      </button>
      <mat-menu #userMenu="matMenu" [overlapTrigger]="false">

        <button mat-menu-item>
          <mat-icon>account_circle</mat-icon>
          <span>{{ staff?.username }}</span>
        </button>

        <!-- <button mat-menu-item class="">
          <mat-icon>mail</mat-icon>
          <span>Inbox</span>
        </button> -->

        <button mat-menu-item (click)="goToChangePassword()">
          <mat-icon>lock</mat-icon>
          <span>Change Password</span>
        </button>

        <button mat-menu-item class="" (click)="logout()">
          <mat-icon>exit_to_app</mat-icon>
          <span>{{ 'LOGOUT' | translate }}</span>
        </button>

        <div mat-menu-item disableRipple disabled class="version-menu">
          <span>v{{ version }}</span>
        </div>

      </mat-menu>

      <!-- <div class="toolbar-separator"></div>

      <fuse-search-bar (input)="search($event)"></fuse-search-bar> -->

      <!-- <div class="toolbar-separator"></div>

      <button mat-button fxHide fxShow.gt-xs class="language-button" [matMenuTriggerFor]="languageMenu">
        <div fxLayout="row" fxLayoutAlign="center center">
          <img class="flag mr-8" [src]="'assets/icons/flags/'+selectedLanguage.flag+'.png'">
          <span class="iso text-uppercase">{{selectedLanguage.id}}</span>
        </div>
      </button>

      <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">

        <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
          <span fxLayout="row" fxLayoutAlign="start center">
            <img class="flag mr-16" [src]="'assets/icons/flags/'+lang.flag+'.png'">
            <span class="iso">{{lang.title}}</span>
          </span>
        </button>

      </mat-menu> -->

      <!-- <div class="toolbar-separator" fxHide fxShow.gt-xs></div>

      <button mat-icon-button class="quick-panel-toggle-button" (click)="toggleSidebarOpen('quickPanel')"
        aria-label="Toggle quick panel">
        <mat-icon class="secondary-text">format_list_bulleted</mat-icon>
      </button>

      <div class="toolbar-separator" *ngIf="!hiddenNavbar && rightNavbar" fxHide fxShow.gt-xs></div>

      <button mat-icon-button class="navbar-toggle-button" *ngIf="!hiddenNavbar && rightNavbar"
        (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
        <mat-icon class="secondary-text">menu</mat-icon>
      </button> -->

    </div>

  </div>

</mat-toolbar>