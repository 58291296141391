import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';
import { currentSelectEnvironment } from '../environments/environment';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { TokenInterceptor } from 'utils/libs/token.interceptor';
import { LoaderModule } from './main/components/loader/loader.module';
import { GetNameByKeyPipe } from './main/pipes/get-name-by-key.pipe';
import { GetValueByKeyPipe } from './main/pipes/get-value-by-key.pipe';

const appRoutes: Routes = [
  {
    path: 'app',
    loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
  },
  // {
  //   path: 'resetpassword',
  //   loadChildren: () => import('./main/pages/reset-password/reset-password.module').then(m => m.ResetPasswordModule)
  // },
  {
    path: 'service',
    loadChildren: () => import('./main/pages/service-pages/service-pages.module').then(m => m.ServicePagesModule)
  },
  {
    path: '**',
    redirectTo: currentSelectEnvironment().firstPage
  }
];

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),

    TranslateModule.forRoot(),
    // InMemoryWebApiModule.forRoot(FakeDbService, configurationFakeDb),

    // Material moment date module
    MatMomentDateModule,

    // Material
    MatButtonModule,
    MatIconModule,

    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    // FuseThemeOptionsModule,

    // App modules
    LayoutModule,
    LoaderModule,

    // Google Maps
    // AgmCoreModule.forRoot({
    //   apiKey: currentSelectEnvironment().googleMap.key,
    //   region: currentSelectEnvironment().googleMap.region,
    //   libraries: [
    //     'places'
    //   ]
    // })
  ],
  bootstrap: [
    AppComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    GetNameByKeyPipe,
    GetValueByKeyPipe,
  ]
})
export class AppModule {
}
