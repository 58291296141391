import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FuseNavigation } from '@fuse/types';

@Component({
  selector: 'app-child-menu-item',
  templateUrl: './child-menu-item.component.html',
  styleUrls: ['./child-menu-item.component.scss']
})
export class ChildMenuItemComponent implements OnInit {

  @Input() items: FuseNavigation[] = [];
  @ViewChild('childMenu', { static: true }) public childMenu;

  constructor(public router: Router) { }

  ngOnInit(): void {
  }

}
