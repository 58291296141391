export const locale = {
  lang: 'en',
  data: {
    NAV: {
      APPLICATIONS: 'Menu',
      DASHBOARD: 'Dashboard',
      STOCK: 'Stock',
      VM_STATUS: 'Kiosk Status',
      STAFF: 'Staff',
      VENDING_CASH: {
        TITLE: 'Vending Cash',
        CASH_INVEN: 'Cash Inventory',
        CHANGE_COIN_ALERT: 'Change Coin Alert',
      },
      LOGANDHISTORY: {
        TITLE: 'Log & History',
        MACHINE_HISTORY: 'Machine History',
        VM_OPERATION: 'VM Operation',
        VM_PERFORMANCE: 'Kiosk Performance',
      },
      REPORT: {
        TITLE: 'Report',
        DAILY_SHIFT_REPORT: 'Sale Shift Report',
        SALE_TRAN_REPORT: 'Successful Transaction Report',
        CANCEL_TRAN_REPORT: 'Cancel Transaction Report',
        STOCK_FILL_REPORT: 'Stock Fill Report',
        STOCK_FILL_BY_PRODUCT_REPORT: 'Stock Fill Group By Product Report',
        STOCK_FILL_BY_DOTRAN_REPORT: 'Stock Fill Group By Transaction Report',
        STOCK_FILL_BY_DAY_REPORT: 'Stock Fill Group By Date Report',
        STOCK_CUTOFF_REPORT: 'Stock Cutoff Report',
        STOCK_ADJUST_REPORT: 'Stock Adjust Report',
        SALE_ERROR_LOG: 'Sale Error Log',
        HARDWARE_ERROR: 'Validate Hardware & Software Error',
        AUDIT_REPORT: 'Audit Report',
        SALE_FAIL_REPORT: 'Failed Transaction Report',
        CASH_MOVEMENT_REPORT: 'Cash Movement Report',
        DISPENSE_REPORT: 'Dispense Report',
        PROMOTION_REPORT: 'Sale By Promotion Report',
        ALL_SALE_TRAN_REPORT: 'All Sales Report',
        PLANOGRAM_REPORT: 'Planogram Report',
        PLANOGRAM_HISTORY_REPORT: 'Planogram History Report',
        COUPON_REPORT: 'Coupon Report',
        SALE_VOID_FAIL_REPORT: 'Void Transaction Report',
        SETTLEMENT: 'Settlement Cash Report',
        SETTLEMENT_CARD: 'Settlement Credit Report',
        BILL_PAYMENT: 'Bill Payment',
        DETAIL_TRAN_REPORT: 'Detail Transaction Report',
        CARD_TRAN_REPORT: 'Credit Card Transaction Report',
        REPORT_TRANSACTIOM: 'Transaction',
      },
      PROMOTION_DATA: {
        TITLE: 'Promotion',
        PROMOTIONS: 'Promotions',
        PROMOTION_PLANS: 'Promotion Plans',
        TAGS: 'Tags',
      },
      COUPON_DATA: {
        COUPONS: 'Coupons',
      },
      VENDING_MASTER: {
        TITLE: 'Vending Master',
        VENDING_CONFIG: 'Vending Config',
        VENDING_PROFILE: 'Vending Profile',
        VENDING_CAT: 'Vending Segment',
        VENDING_GROUP: 'Vending Group',
        VENDING_TYPE: 'Kiosk Model',
        FULLFILLMENT: 'Fullfillment',
        SITEOWNER: 'Site Owner',
        TDS_RGM: 'TDS RGM',
        CBD: 'CBD',
        SHOP: 'Shop',
      },
      MASTER_DATA: {
        TITLE: 'Master Data',
        COMPANY: 'Company',
        BRAND: 'Brand',
        BRANCH: 'Branch',
        PRODUCT: 'Product',
        TAG: 'Tag',
        PRODUCT_CAT: 'Product Category',
        PRODUCT_BASKET: 'Product Basket',
        BROWSING_MENU: 'Browsing Menu',
        SLOT_TEMPLATE: 'Planogram Template',
      },
      ROLE_MANAGEMENT: 'Role Management',
      MORE: {
        TITLE: 'More',
      },
      ADS_CAMPAIGN: {
        TITLE: 'Ads',
        TITLE_MIDDLE: 'Banner 1',
        TITLE_BOTTOM: 'Banner 2',
        CAMPAIGN: 'Ads Campaign',
        ADS_DISPLAY_GROUP: 'Ads Display Group',
        ADS_PLAN: 'Ads Campaign Plan',
        CAMPAIGN_MIDDLE: 'Banner 1 Campaign',
        ADS_DISPLAY_GROUP_MIDDLE: 'Banner 1 Display Group',
        ADS_PLAN_MIDDLE: 'Banner 1 Campaign Plan',
        CAMPAIGN_BOTTOM: 'Banner 2 Campaign',
        ADS_DISPLAY_GROUP_BOTTOM: 'Banner 2 Display Group',
        ADS_PLAN_BOTTOM: 'Banner 2 Campaign Plan',
      },
      PAYMENT_CONFIG: {
        TITLE: 'Payment Config',
        TMN_PAYMENT: 'TMN Payment Config',
        TMN_BILL: 'TMN Bill Config',
        TMN_TOPUP: 'TMN Topup Config',
        WEOMNI_CONFIG: 'Weomni Config',
      },
    },
  },
};
