import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private loaderSubject = new BehaviorSubject<LoaderState>({
    show: false
  });

  loaderState = this.loaderSubject.asObservable();

  constructor() { }

  public show(showText: string = null): void {
    this.loaderSubject.next({ show: true, showText });
  }

  public hide(): void {
    this.loaderSubject.next({ show: false });
  }
}

export interface LoaderState {
  show: boolean;
  showText?: string;
}
