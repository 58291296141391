export const locale = {
  lang: 'th',
  data: {
    Create: 'สร้าง',
    Update: 'อัพเดท',
    Cancel: 'ยกเลิก',
    BRAND: 'แบรนด์',
    COMPANY: 'บริษัท',
    STAFF: 'พนักงาน',
    ROLE: 'สิทธิ',
    LOGOUT: 'ออกจากระบบ',
    PICK_DATE_REPORT: 'เลือกวันที่รายงาน',
    CLOSE: 'ปิด',
    EXPORT: 'ส่งออก',
    START_DATE: 'วันที่เริ่ม',
    End_DATE: 'วันที่สิ้นสุด',
    ALLOW_31_DAYS: 'อนุญาตให้ดึงข้อมูล 31 วันเท่านั้น',
    SEC: 'sec',
    FORM: {
      ENABLE: 'เปิดใช้งาน',
      INVALID: {
        REQUIRED: 'จำเป็นต้องใช้',
        EMAIL: 'อีเมล์ไม่ถูกต้อง',
        MUST_BE_8_LONG: 'ต้องมีความยาวอย่างน้อย 8 ตัวอักษร',
        AZ_09_ONLY: 'A-z หรือ 0-9 เท่านั้น',
        ENG_OR_THAI: 'อังกฤษ หรือ ไทย เท่านั้น',
        ID_FORMAT: 'A-z, 0-9 และ _ เท่านั้น',
        PHONENUMBER: 'เบอร์โทรศัพท์ ไม่ถูกต้อง (ตัวอย่าง: 0986458760)',
        UPPER_SYMBOL: 'ต้องประกอบด้วยตัวอักษรพิมพ์ใหญ่, พิมพ์เล็ก, ตัวเลขและอักขระพิเศษอย่างน้อยชนิดละ 1 ตัว',
        NUMBER_ONLY: 'numeric character [0-9] only',
        PASSWORD: 'A-z, [0-9] ตัวอักษรและเลขอย่างน้อยชนิดละ 1 ตัว',
        USERNAME: 'A-z, 0-9 และ . - _ @ เท่านั้น'
      }
    },
    TABLE: {
      ADDNEW: 'เพิ่มใหม่',
      EXPORT: 'ส่งออก',
      SEARCH: 'ค้นหา'
    }
  }
};
