import { Pagination } from './core.model';

export class StaffRole {
  id: string = null;
  roleName: string = null;
  access: string | RoleConsole = null;
  applications: ApplicationsRole[] = [];
  brandResource: string[] = [];
  machineResource: string[] = [];
  companyResource: string[] = [];
}

export class GetStaffRolePaging {
  pagination: Pagination;
  staff_role: StaffRole[];
}

export class RolePage {
  public id: string = null;
  public name: string = null;
  public active = false;
  public canCreate = false;
  public canUpdate = false;
  public canDelete = false;
  public canExport = false;
  public haveCreate = false;
  public haveUpdate = false;
  public haveDelete = false;
  public haveExport = false;
}

export class RoleConsole {
  public pages: RolePage[] = [];
}

export enum ApplicationsRole {
  BACKEND = 'BACKEND',
  MACHINE = 'MACHINE'
}
