import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class IconRegistryService {

  private iconRegister: ICustomIcon[] = [
    {
      iconName: 'dashboard_icon',
      url: 'assets/icons/toolbar/icn_menu_dashboard_black.svg'
    },
    {
      iconName: 'stock_icon',
      url: 'assets/icons/toolbar/icn_menu_stock_black.svg'
    },
    {
      iconName: 'vm_status_icon',
      url: 'assets/icons/toolbar/icn_menu_vmstatus_black.svg'
    },
    {
      iconName: 'report_icon',
      url: 'assets/icons/toolbar/icn_menu_report_black.svg'
    },
    {
      iconName: 'more_icon',
      url: 'assets/icons/toolbar/icn_menu_more_black.svg'
    },
    {
      iconName: 'master_data_icon',
      url: 'assets/icons/toolbar/icn_menu_masterdata_black.svg'
    },
    {
      iconName: 'auth_icon',
      url: 'assets/icons/toolbar/icn_menu_authori_black.svg'
    },
    {
      iconName: 'campaign_icon',
      url: 'assets/icons/toolbar/icn_menu_promotion_black.svg'
    },
    {
      iconName: 'vm_cash_icon',
      url: 'assets/icons/toolbar/icn_menu_vmcash_black.svg'
    },
    {
      iconName: 'login_username_icon',
      url: 'assets/icons/toolbar/icn_login_username.svg'
    },
    {
      iconName: 'key_pwd_icon',
      url: 'assets/icons/toolbar/icn_login_password.svg'
    },
    {
      iconName: 'add_business',
      url: 'assets/icons/toolbar/add_business_black_24dp.svg'
    }
  ];

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {
    this.init();
  }

  private init(): void {
    this.iconRegister.forEach(icon => {
      this.iconRegistry.addSvgIcon(
        icon.iconName,
        this.sanitizer.bypassSecurityTrustResourceUrl(icon.url)
      );
    });
  }
}

interface ICustomIcon {
  iconName: string;
  url: string;
}
