<mat-menu #childMenu="matMenu" [overlapTrigger]="false">
  <span *ngFor="let child of items">
    <!-- Handle branch node menu items -->
    <span *ngIf="child.children && child.children.length > 0 && !child.hidden">
      <button mat-menu-item color="primary" [matMenuTriggerFor]="menu.childMenu">
        <mat-icon *ngIf="child.icon && !child.isCustomIcon">{{child.icon}}</mat-icon>
        <mat-icon *ngIf="child.icon && child.isCustomIcon" [svgIcon]="child.icon"></mat-icon>
        <span>{{(child.translate | translate) || child.title}}</span>
      </button>
      <app-child-menu-item #menu [items]="child.children"></app-child-menu-item>
    </span>
    <!-- Handle leaf node menu items -->
    <span *ngIf="!child.children || child.children.length === 0">
      <a mat-menu-item [routerLink]="child.url" [routerLinkActive]="['active', 'active-link']"
        [routerLinkActiveOptions]="{exact: child.exactMatch || false}" style="text-decoration: none"
        *ngIf="!child.hidden">
        <mat-icon *ngIf="child.icon && !child.isCustomIcon" [routerLinkActive]="['active', 'active-link']"
          [routerLinkActiveOptions]="{exact: child.exactMatch || false}">{{child.icon}}</mat-icon>
        <mat-icon *ngIf="child.icon && child.isCustomIcon" [svgIcon]="child.icon"
          [routerLinkActive]="['active', 'active-link']" [routerLinkActiveOptions]="{exact: child.exactMatch || false}">
        </mat-icon>
        <span>{{(child.translate | translate) || child.title}}</span>
      </a>
    </span>
  </span>
</mat-menu>