import { Pagination } from './core.model';
import { StaffRole } from './staff-role.model';

export class Staff {
  id: string;
  staffCode: string;
  username: string;
  name: string;
  email: string;
  firstName: string;
  lastName: string;
  nickname: string;
  imageUrl: string;
  tel: string;
  staff_role: StaffRole;
  isEnable: boolean;
  role: E_JobPosition;

  constructor(staff: Staff) {
    {
      this.id = staff?.id;
      this.staffCode = staff?.staffCode;
      this.username = staff?.username;
      this.name = staff?.name;
      this.email = staff?.email;
      this.firstName = staff.firstName || null;
      this.lastName = staff.lastName || null;
      this.nickname = staff.nickname || null;
      this.imageUrl = staff.imageUrl || null;
      this.tel = staff.tel || null;
      this.staff_role = staff.staff_role || null;
      this.isEnable = staff.isEnable || null;
    }
  }
}

export interface StaffForm {
  id?: string;
  firstName: string;
  lastName: string;
  name?: string;
  email: string;
  username?: string;
  password?: string;
  imageUrl?: string;
  staffCode: string;
  tel: string;
  isEnable: boolean;
  staffRoleID?: string;
  staff_role?: StaffRole;
  newPinCode?: string;
  role?: E_JobPosition;
}

export interface GetStaffPaging {
  pagination: Pagination;
  staff: Staff[];
}

export enum E_JobPosition {
  STAFF = 'STAFF',
  ADMIN = 'ADMIN',
  // ROOT_ADMIN = 'ROOT_ADMIN',
  TERRITORY = 'TERRITORY',
  STOCK = 'STOCK',
  ACCOUNTING = 'ACCOUNTING'
}
