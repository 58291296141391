import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService, LoaderState } from 'utils/services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {

  // Setting
  public color = 'primary'; // primary, accent, warn
  public strokeWidth = 5;
  public diameter = 40;

  public show = false;
  public showText = null;

  private subscription: Subscription;

  constructor(
    private loaderService: LoaderService
  ) { }

  ngOnInit(): void {
    this.subscription = this.loaderService.loaderState
      .subscribe((state: LoaderState) => {
        this.show = state.show;
        this.showText = state.showText;
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
