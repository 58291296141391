import { Observable, of, throwError } from 'rxjs';
import { retryWhen, delay, mergeMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { BaseAPIEntity, BaseErrorData } from 'app/models/core.model';

const DEFAULT_MAX_RETRIES = 5;

/**
 * Deley Retries
 *
 * @export
 * @param {number} delayMs
 * @param {*} [maxRetry=DEFAULT_MAX_RETRIES]
 * @returns
 */
export function delayedRetry(delayMs: number, maxRetry = DEFAULT_MAX_RETRIES): any {
  let retries = maxRetry;
  return (src: Observable<any>) => {
    return src.pipe(
      retryWhen((errors: Observable<any>) => {
        return errors.pipe(
          delay(delayMs),
          mergeMap((error) => {
            return retries-- > 0 ? of(error) : throwError(error);
          })
        );
      })
    );
  };
}

export class HandlerError {

  public status = false;
  public titleEN = '';
  public titleTH = '';
  public msgEN = '';
  public msgTH = '';
  public code = null;

  constructor(data: any) {
    if (data instanceof HttpErrorResponse) {
      this.status = true;

      console.log(data.error);

      const resError: BaseAPIEntity<any> = data.error;

      if (resError && resError.errors) {
        let error: BaseErrorData = null;

        if (Object.keys(resError.errors).length !== 0 && resError.errors.messages) {
          try {
            error = resError.errors;
            this.titleEN = `(${error?.statusCode}) ${error?.messages?.th}`;
            this.titleTH = `(${error?.statusCode}) ${error?.messages?.en}`;
            this.msgEN = error?.messages?.th;
            this.msgTH = error?.messages?.en;
            this.code = error?.statusCode;
          } catch {
            error = data.error;
            this.titleEN = `${error?.message}`;
            this.titleTH = `${error?.message}`;
            this.msgEN = error?.message;
            this.msgTH = error?.message;
            this.code = error?.statusCode;
          }
        } else {
          error = data.error;
          this.titleEN = `${error?.message}`;
          this.titleTH = `${error?.message}`;
          this.msgEN = error?.message;
          this.msgTH = error?.message;
          this.code = error?.statusCode;
        }
      } else {
        this.titleEN = `${data.name}`;
        this.titleTH = `${data.name}`;
        this.msgEN = data.message;
        this.msgTH = data.message;
        this.code = data?.status;
      }
    }
  }
}
