import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject, of } from 'rxjs';
import { currentSelectEnvironment } from '../../../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { Staff } from 'app/models/staff.model';
import { delayedRetry } from 'utils/libs/http-request';
import { LocalStorageService } from 'utils/services/local-storage.service';
import { LocalKey } from 'utils/interfaces/local-key';
import { BaseAPIEntity } from 'app/models/core.model';
import { ISignIn, ISignInByEmail } from 'app/models/authentication.model';
import { LoaderService } from 'utils/services/loader.service';
import { HEX, SHA512 } from 'utils/libs/cryptojs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _staff = new BehaviorSubject<Staff | null>(null);
  public get staff(): Staff {
    return this._staff.value;
  }
  public set staff(data: Staff) {
    this.localStorageService.saveDataLocal(LocalKey.Staff, data);
    this._staff.next(data);
  }
  public get conpanys(): string[] {
    return this._staff.value?.staff_role?.companyResource || [];
  }
  public get machines(): string[] {
    return this._staff.value?.staff_role?.machineResource || [];
  }

  private _token = null;
  public get token(): string {
    return this._token;
  }
  public set token(data: string) {
    this.localStorageService.saveDataLocal(LocalKey.Token, data);
    this._token = data;
  }

  private apiPath = {
    login: 'rest/staff/login',
    loginByEmail: 'rest/staff/login_by_email',
    refreshToken: 'rest/staff/refresh_token',
    forgotPassword: 'rest/staff/forgot_password',
    resetPassword: 'api/v1/staff/resetpassword',
    reportUserInvalidate: 'api/v1/staff/roles-break'
  };

  private mockApiPath = {
    login: 'api/staff/'
  };

  constructor(
    private httpClient: HttpClient,
    private localStorageService: LocalStorageService,
    private loaderService: LoaderService
  ) { }

  /**
   * Get Staff Observable
   * @returns {Observable<Staff>}
   * @memberof AuthService
   */
  public getStaffObs(): Observable<Staff> {
    return this._staff.asObservable();
  }

  /**
   * Staff Login
   * @param {{ email: string, password: string }} data
   * @returns {Observable<any>}
   * @memberof AuthService
   */
  public login(data: { email: string, username: string, password: string }): Observable<ISignIn> {
    return this.httpClient.post(`${currentSelectEnvironment().main_api}/${this.apiPath.login}`, {
      // email: data.email,
      username: data.username,
      password: data.password
    }).pipe(
      delayedRetry(500, 0),
      map((res: BaseAPIEntity<ISignIn>) => {
        return res.result;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  /**
   * Staff Login By Email
   * @param {{ email: string, username: string, password: string }} data
   * @return {*}  {Observable<ISignInByEmail>}
   * @memberof AuthService
   */
  public loginByEmail(data: { email: string, username: string, password: string }): Observable<ISignInByEmail> {
    return this.httpClient.post(`${currentSelectEnvironment().main_api}/${this.apiPath.loginByEmail}`, {
      email: data.username,
      password: data.password
    }).pipe(
      delayedRetry(500, 0),
      map((res: BaseAPIEntity<ISignInByEmail>) => {
        return res.result;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  /**
   * Staff Logout
   * @returns {Promise<void>}
   * @memberof AuthService
   */
  public logout(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.staff = null;
      this.localStorageService.removeAllLocal();
      this.localStorageService.removeAllSession();
      this.loaderService.hide();
      resolve();
    });
  }

  /**
   * Check Staff Logged
   * @returns {Promise<boolean>}
   * @memberof AuthService
   */
  public checkLogged(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const staff = this.localStorageService.getDataLocal(LocalKey.Staff);
      const token = this.localStorageService.getDataLocal(LocalKey.Token);
      if (staff && token) {
        this.token = token;
        this.staff = staff;
        resolve(true);
      } else {
        resolve(false);
      }
    });
  }

  /**
   * Refresh Token
   * @returns {Observable<ISignIn>}
   * @memberof AuthService
   */
  public refreshToken(): Observable<ISignIn> {
    return this.httpClient.post(`${currentSelectEnvironment().main_api}/${this.apiPath.refreshToken}`, null).pipe(
      delayedRetry(1000, 0),
      map((res: BaseAPIEntity<ISignIn>) => {
        return res.result;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  /**
   * Forgot Password
   * @param {string} email
   * @returns {Observable<any>}
   * @memberof AuthService
   */
  public forgotPassword(email: string): Observable<any> {
    return this.httpClient.post(`${currentSelectEnvironment().main_api}/${this.apiPath.forgotPassword}`, {
      email: email
    }).pipe(
      delayedRetry(1000, 0),
      map((res: BaseAPIEntity<any>) => {
        return res.result;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  /**
   * Reset Password
   * @param {*} data
   * @returns {Observable<any>}
   * @memberof AuthService
   */
  public resetPassword(data: any): Observable<any> {
    return this.httpClient.post(`${currentSelectEnvironment().main_api}/${this.apiPath.resetPassword}`, {
      ...data
    }).pipe(
      delayedRetry(1000, 0),
      map((res: BaseAPIEntity<any>) => {
        return res.result;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  /**
   * Check Staff Has Brand
   * @returns {boolean}
   * @memberof AuthService
   */
  public staffHasBrand(): boolean {
    if (this.staff) {
      return true;
    }
    return false;
  }

  public hashRole(str: string): string {
    const hashed = SHA512(str + '_' + 'y3UXfAz8jcRU2zsHhKjgrQnrRTU36L');
    return hashed.toString(HEX);
  }

  public reportUserInvalidate(): Observable<any> {
    return of(true);
    return this.httpClient.post(`${currentSelectEnvironment().main_api}/${this.apiPath.reportUserInvalidate}`, {}).pipe(
      delayedRetry(1000, 0),
      map((res: BaseAPIEntity<any>) => {
        return res.result;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  /**
   * Mocking Login
   *
   * @param {{ email: string, password: string }} data
   * @return {*}  {Observable<Staff>}
   * @memberof AuthService
   */
  public mocklogin(data: { email: string, password: string }): Observable<ISignIn> {
    return this.httpClient.get(`${currentSelectEnvironment().main_api}/${this.mockApiPath.login}`, {
      params: {
        username: data.email.split('@')[0]
      }
    }).pipe(
      delayedRetry(1000, 0),
      map((res: any) => {
        if (res.length < 1) {
          throw new Error('Email or Password incorrect.');
        }

        return {
          staff: new Staff(res[0]),
          Authorization: 'MockToken',
          signKey: null
        } as ISignIn;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
}
