<div class="container_header" fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="0px" fxLayout.xs="column"
  [ngStyle]="{'padding-bottom': paddingBottom}">
  <div fxFlex="auto" fxLayoutAlign="start start">
    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">
      <div>
        <span class="title">{{ data?.title }}</span>
      </div>
      <button *ngIf="data?.pathBackBtn" mat-stroked-button [routerLink]="data.pathBackBtn">
        Back
      </button>
    </div>
  </div>
  <div fxFlex="auto" fxLayout="row wrap" fxLayoutAlign="end start" fxLayoutGap="10px" fxLayoutAlign.xs="center start"
    style="row-gap: 10px;">
    <div>
      <span *ngIf="data?.dateLatestSync" class="date_latest_sync">{{ data.dateLatestSync }}</span>
    </div>
    <button mat-raised-button class="light-blue-600-bg fuse-white-500-fg" (click)="onClickPublish()"
      *ngIf="data?.publish && (data?.publish?.enable && data?.publish?.id && data?.publish?.masterType)">
      <mat-icon inline>publish</mat-icon> Publish
    </button>
    <button *ngIf="data?.pathRouteCreateBtn && canCreate" mat-flat-button color="accent"
      [routerLink]="data.pathRouteCreateBtn">
      <mat-icon inline>add</mat-icon> {{ 'TABLE.ADDNEW' | translate }}
    </button>
    <button *ngIf="data?.showImportBtn" mat-flat-button color="accent" (click)="onClickImport()">
      Import
    </button>
    <button *ngIf="data?.showExportBtn && canExport" mat-flat-button color="accent" (click)="onClickExport()">
      <mat-icon inline>archive</mat-icon> {{ 'TABLE.EXPORT' | translate }}
    </button>
  </div>
</div>