import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { get } from 'lodash-es';

@Injectable()
@Pipe({
  name: 'getValueByKey',
})
export class GetValueByKeyPipe implements PipeTransform {
  transform(value: any, name: string): string {
    try {
      const resValue = get(value, name);

      if (
        resValue === undefined ||
        resValue === null ||
        resValue === '' ||
        resValue === 'undefined' ||
        resValue === 'null'
      ) {
        return '';
      }

      return resValue;
    } catch {
      return '';
    }
  }
}
