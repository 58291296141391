import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable()
@Pipe({
  name: 'getNameByKey',
})
export class GetNameByKeyPipe implements PipeTransform {
  transform(value: string): string {
    try {
      const array = value.split('.');
      const resValue = array[array.length - 1];

      if (
        resValue === undefined ||
        resValue === null ||
        resValue === '' ||
        resValue === 'undefined' ||
        resValue === 'null'
      ) {
        return '';
      }

      return resValue;
    } catch {
      return '';
    }
  }
}
